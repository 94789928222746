<template>
<div class="card">
  <h5 class="card-header">
    <div v-if="securityValidationStatus !== false">
      <div v-if="securityValidationStatus === 1" class="d-flex" style="gap: 10px;">
        <i class="fas fa-times-circle text-danger main-status-icon"></i>
        <span>Validated as unsecure</span>
      </div>
      <div v-if="securityValidationStatus === 0" class="d-flex" style="gap: 10px;">
        <i class="fas fa-check-circle text-success main-status-icon"></i>
        <span>Validated as safe</span>
      </div>
    </div>
    <div v-else>
      Security Validation
    </div>
  </h5>
  <div class="card-body d-flex flex-column" style="gap: 16px;">

    <div class="d-flex flex-column" style="gap: 24px;">
      <div class="d-flex flex-column" style="gap: 4px;">
        <div  v-for="(value, key) in securityValidationChecks" :key="key" class="d-flex" style="gap: 8px;">
          <i v-if="value.status === 1" class="fas fa-check-circle text-success status-icon"></i>
          <i v-if="value.status === 0" class="fas fa-times-circle text-danger status-icon"></i>
          <i v-if="value.status === null" class="fas fa-minus-circle text-secondary status-icon"></i>
          <div class="d-flex" style="gap: 4px;">
            <LabeledData :label="value.label" :value="value.message" class="d-flex align-items-center"/>
          </div>
        </div>
      </div>

      <div v-if="!more_info">
        If the credit card billing address doesn't match the pickup... <span class="text-primary" @click="more_info = true" style="cursor: pointer;">More info</span>
      </div>
      <div v-else>
        If the credit card billing address doesn't match the pickup or delivery address, please check with the customer to understand why. Ensure that you are speaking with a trustworthy customer. If the situation seems suspicious, ask for an alternative credit card or offer electronic payment options such as Zelle, Cash App, or Venmo.
        <span class="text-primary" @click="more_info = false" style="cursor: pointer;">Less info</span>
      </div>
    </div>

    <div v-if="securityValidation.overrideReason">
      <div v-if="show_comments">
        {{overrideUser}} - {{securityValidation.overrideAt}}<br>
        <b>{{securityValidation.overrideReason}}</b>
      </div>
      <div @click="show_comments = !show_comments"  class="text-primary" style="cursor: pointer;">{{show_comments? 'Hide Comments' : 'Open Comments'}}</div>
    </div>

    <div v-if="securityValidationStatus === 1" class="d-flex flex-column" style="gap: 4px;">
      <div class="text-danger">
        Before saving it, please explain why you want to proceed despite not all security measures being resolved.
      </div>
      <div class="new-note-form">
        <Mentionable
            :keys="['@']"
            :items="$store.state.users.mentionableUsers"
            offset="6"
          >
          <b-textarea placeholder="Explain why to proceed" :rows="2" :max-rows="3" class="form-control" v-model="securityValidationNote"/>
          <template #no-result>
            {{ $store.state.users.loadingMentionableUsers ? 'Loading...' : 'No result' }}
          </template>
        </Mentionable>
        <button class="btn btn-primary ml-1" @click="sendComment()">
          <i class="fas fa-paper-plane"></i>
        </button>
      </div>
    </div>

  </div>
</div>
      
</template>

<script>
import LabeledData from '@/components/shared/LabeledData.vue'
import { ApiClient } from '@/api/ApiClient';
import { Mentionable } from 'vue-mention'

export default {
  name: 'SecurityValidation',
  components: {
    LabeledData,
    Mentionable
  },
  props: {
    securityValidation: {
      type: Object,
      required: true,
    },
    lead_id: {
      type: Number,
      required: true,
    }
  },
  data() {
    return {
      more_info: false,
      show_comments: false,
      securityValidationNote:''	,
    };
  },
  mounted() {
    this.loadOverrideUser();
  },
  computed: {
    securityValidationStatus() {
      return this.securityValidation?.requiresValidation ?? 0;
    },
    securityValidationChecks() {
      const checks = this.securityValidation?.checks;
      try {
        return checks ? JSON.parse(checks) : null;
      } catch (e) {
        return null;
      }
    },
  },
  methods: {
    async loadOverrideUser() {
      const overrideUserId = this.securityValidation.overrideUserId;
      if (!overrideUserId) return;

      try {
        const response = await ApiClient.users.get({ id: overrideUserId });
        if (response.status === 200) {
          this.overrideUser = response.data.data.username;
        }
      } catch (e) {
        console.error('Failed to fetch override user:', e);
      }
    },
    async sendComment() {
      const response = await ApiClient.leads.securityValidationMessage({
        id: this.lead_id,
        body: { override_reason: this.securityValidationNote },
      });
      if(response.status === 200){
        this.securityValidationNote = ''
        this.$emit('updated', response.data.data.securityValidation)
      }
    },
  },
  watch: {
    'securityValidation.overrideUserId': {
      immediate: true,
      handler() {
        this.loadOverrideUser();
      }
    }
  }
};
</script>
<style lang="scss" scoped>

.new-note-form {
  display: flex;
  height: 60px;
  align-items: center;
  margin-bottom: 24px;
}

.status-icon {
  padding-top: 4px;
  font-size: 14px;
  width: 14px;
  height: 14px;
}

.main-status-icon {
  font-size: 24px;
  width: 24px;
  height: 24px;
}
</style>
